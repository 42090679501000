<template>
  <dimmer :active="loading">
    <div class="container print-hidden">
      <modal :visible="reviewModalKey ? true : false" @close="reviewModalKey = null">
        <review-container
          v-if="reviewModalKey"
          :reviews="currentReview"
          :viewOnly="true"
          @submit="reviewModalKey = null"
          @accept="acceptReview"
          @reject="rejectReview"
        />
      </modal>

      <modal :visible="newReview ? true : false" @close="newReview = null">
        <div  style="min-width: 500px;">
          <review-editor
            v-if="newReview"
            :review="newReview"
            @submit="addNewReview"/>
        </div>
      </modal>

      <alert
        v-if="hasMissingPortions"
        :can-be-closed="false"
        type="warning"
        message="There's a missing portion in the ingredients.">
      </alert>

      <alert
        v-if="hasPendingIngredients"
        :can-be-closed="false"
        type="warning">
        <span v-if="isNew">Publishing disabled.</span> Some <a href="#" @click.prevent="tab = 'ingredients'">ingredients</a> are still waiting for approval.
      </alert>

      <alert
        v-if="!can(uiPermissions.RECIPES_UPDATE)"
        :can-be-closed="false"
        type="warning"
        message="You don't have permission to edit this recipe.">
      </alert>

      <alert
        v-if="item.archived"
        :can-be-closed="false"
        type="warning"
        message="This is an archived recipe.">
      </alert>

      <alert
        v-if="recipeSwapError"
        type="danger"
        :message="recipeSwapError"
        @close="() => recipeSwapError = ''"/>

      <edit-header
        entity="recipe"
        :error.sync="error"
        :invalid.sync="invalid"
        :invalid-message="invalidMessage"
        :submitted.sync="submitted"
        :isNew="isNew">
        <div class="d-flex align-items-center w-100">
          <router-link
            to="/recipes"
            class="btn btn-secondary mr-2">
            <i class="fe fe-chevron-left"></i> Back
          </router-link>

          <ul
            class="nav nav-tabs w-100 mx-0"
            style="margin-top: -1rem;"
            role="tablist">
            <li class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'general'}"
                role="tab"
                @click="tab = 'general'">General</a>
            </li>
            <li class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'ingredients'}"
                role="tab"
                @click="tab = 'ingredients'">Ingredients</a>
            </li>
            <li v-if="!externalReview" class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'nutritionals'}"
                role="tab"
                @click="tab = 'nutritionals'">Nutritionals</a>
            </li>
            <li class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'method'}"
                role="tab"
                @click="tab = 'method'">Method</a>
            </li>
            <li v-if="!isNew && !externalReview" class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'feedback'}"
                role="tab"
                @click="tab = 'feedback'">Feedback</a>
            </li>
            <li v-if="!externalReview && recipeCategory === RECIPE_CATEGORY.MARKET" class="nav-item">
              <a
                tabindex="0"
                class="nav-link"
                :class="{'active': tab === 'market'}"
                role="tab"
                @click="tab = 'market'">Market</a>
            </li>
          </ul>
        </div>
      </edit-header>

      <div
        v-if="item.status"
        slot="options"
        class="h4"
      >
        Recipe Status: {{statusName}}
      </div>

      <form
        v-disable-all.ignore="disableFormInputs"
        ref="form"
        :key="item.id"
        class="validation"
        novalidate
        @submit.prevent="submit">
        <div class="tab-content">
          <!-- general tab -->
          <div
            class="tab-pane fade"
            :class="{'show active': tab === 'general'}"
            role="tabpanel">
            <div class="row">
              <div :class="isNew ? 'col' : 'col-xl-8'">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Latest version of recipe</h3>
                    <div class="card-options">
                      <template v-if="isPeerReview">
                        <div>
                          <label class="custom-switch mr-2 mt-2">
                            <input
                              v-model="peerReviewEditMode"
                              type="checkbox"
                              class="custom-switch-input"
                            >
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Edit Mode </span>
                          </label>
                        </div>
                      </template>
                      <template v-if="externalReview">
                        <review-submit-buttons
                          @draft="saveReview('pending')"
                          @submit="saveReview('completed')"
                        >
                        </review-submit-buttons>
                      </template>
                    </div>
                  </div>
                  <div class="card-body">
                    <div v-if="!externalReview" class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label"></label>
                      <div class="col-sm-9">
                        <image-input
                          v-model="item.photo"
                          :tags="['recipe', 'plated shot']"
                          :preview.sync="item.thumbnail"
                          :thumbnail-options="{height: 80, width: 80, crop: 'fill'}"
                          class="avatar avatar-xxl"
                          @error="handleImageError"></image-input>
                      </div>
                    </div>
                    <div v-if="!externalReview" class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Weekly classic</label>
                      <div class="col-sm-9 d-flex align-items-center">
                        <label class="custom-switch m-0">
                          <input
                            v-model="item.weeklyClassic"
                            type="checkbox"
                            class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Recipe name</label>
                      <div
                        class="col-sm-9"
                        :class="reviews.name || (externalReview && currentReviews.name) ? 'highlighted' : ''"
                      >
                        <input
                          v-model="item.name"
                          type="text"
                          class="form-control"
                          required
                          :readonly="externalReview"
                          @click="(externalReview || reviews.name) && handleInput('name', 'Recipe Name')"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Display name 1</label>
                      <div
                        class="col-sm-9"
                        :class="reviews.cardName1 || (externalReview && currentReviews.cardName1) ? 'highlighted' : ''"
                      >
                        <input
                          v-model="item.cardName1"
                          type="text"
                          class="form-control"
                          :readonly="externalReview"
                          @click="(externalReview || reviews.cardName1) && handleInput('cardName1', 'Display name 1')"
                        />
                        <div class="text-right" :class="{'char-limit': isTitleCharLimit}">
                          <span
                            v-if="isTitleCharLimit"
                            v-b-tooltip="'The title might be too lengthy for the website to display.'"
                            class="fe fe-info"/><small>{{(item.cardName1 || '').length}}/191</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Display name 2</label>
                      <div
                        class="col-sm-9"
                        :class="reviews.cardName2 || (externalReview && currentReviews.cardName2) ? 'highlighted' : ''"
                      >
                        <input
                          v-model="item.cardName2"
                          type="text"
                          class="form-control"
                          :readonly="externalReview"
                          @click="(externalReview || reviews.cardName2) && handleInput('cardName2', 'Display name 2')"
                        />
                        <div class="text-right" :class="{'char-limit': isSubtitleCharLimit}">
                          <span
                            v-if="isSubtitleCharLimit"
                            v-b-tooltip="'The subtitle might be too lengthy for the website to display.'"
                            class="fe fe-info"/><small>{{(item.cardName2 || '').length}}/191</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Introduction</label>
                      <div
                        class="col-sm-9"
                        :class="reviews.introduction || (externalReview && currentReviews.introduction) ? 'highlighted' : ''"
                      >
                        <input
                          v-model="item.introduction"
                          type="text"
                          class="form-control"
                          maxlength="191"
                          :readonly="externalReview"
                          @click="(externalReview || reviews.introduction) && handleInput('introduction', 'Introduction')"
                        />
                        <div class="text-right">
                          <small>{{(item.introduction || '').length}}/191</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="!externalReview" class="form-group row d-flex mb-0">
                      <label class="col-sm-3 col-form-label">Nutritional info</label>
                      <div class="col-sm-9">
                        <div class="form-row">

                          <div
                            v-for="nutrition in item.nutritions"
                            :key="nutrition.id"
                            class="col-6">
                            <div class="form-group row d-flex">

                              <label class="col-sm-4">{{nutrition.name}}</label>
                              <div class="col-sm-8">
                                <div class="tag">{{nutrition.value}}</div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Cooking time</label>
                      <div class="col-sm-9">
                        <div
                          class="input-group"
                          :class="reviews.cookingTime || (externalReview && currentReviews.cookingTime) ? 'highlighted' : ''"
                        >
                          <input
                            v-model="item.cookingTime"
                            :type="(externalReview || reviews.cookingTime) ? 'text' : 'number'"
                            class="form-control"
                            min="0"
                            :readonly="externalReview"
                            @click="(externalReview || reviews.cookingTime) && handleInput('cookingTime', 'Cooking time')"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">min.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Prep time</label>
                      <div class="col-sm-9">
                        <div
                          class="input-group"
                          :class="reviews.prep_time || (externalReview && currentReviews.prep_time) ? 'highlighted' : ''"
                        >
                          <input
                            v-model="item.prep_time"
                            :type="(externalReview || reviews.prep_time) ? 'text' : 'number'"
                            class="form-control"
                            min="0"
                            :readonly="externalReview"
                            @click="(externalReview || reviews.prep_time) && handleInput('prep_time', 'Prep time')"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">min.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">Main ingredient</label>
                      <div
                        class="col-sm-9"
                        :class="reviews.mainProtein || (externalReview && currentReviews.mainProtein) ? 'highlighted' : ''"
                        @click="(externalReview || reviews.mainProtein) && handleInput('mainProtein', 'Main ingredient', 'select', proteins)"
                      >
                        <template v-if="externalReview || reviews.mainProtein">
                          <input
                            v-if="mainProtein"
                            type="text"
                            class="form-control"
                            :readonly="true"
                            :value="mainProtein.name"
                          />
                        </template>
                        <template v-else>
                          <select
                            v-model="mainProtein"
                            class="form-control custom-select"
                          >
                            <option :value="null" disabled>Select ingredient</option>
                            <option
                              v-for="item in proteins"
                              :key="item.id"
                              :value="item">
                              {{item.name}}
                            </option>
                          </select>
                        </template>
                      </div>
                    </div>
                    <div v-if="!externalReview" class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">2 people surcharge</label>
                      <div class="col-sm-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AED</span>
                          </div>
                          <input
                            v-model="item.gourmetSurchargeFor2"
                            type="number"
                            step=".01"
                            class="form-control"
                            :min="item.features.find(item => item.name.toLowerCase() === 'gourmet') ? 1 : 0"
                            :required="item.features.find(item => item.name.toLowerCase() === 'gourmet')"/>
                          <div class="input-group-append">
                            <span class="input-group-text">per serving</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!externalReview" class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">3 people surcharge</label>
                      <div class="col-sm-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AED</span>
                          </div>
                          <input
                            v-model="item.gourmetSurchargeFor3"
                            type="number"
                            step=".01"
                            class="form-control"
                            :min="item.features.find(item => item.name.toLowerCase() === 'gourmet') ? 1 : 0"
                            :required="item.features.find(item => item.name.toLowerCase() === 'gourmet')"/>
                          <div class="input-group-append">
                            <span class="input-group-text">per serving</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!externalReview" class="form-group row d-flex">
                      <label class="col-sm-3 col-form-label">4 people surcharge</label>
                      <div class="col-sm-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AED</span>
                          </div>
                          <input
                            v-model="item.gourmetSurchargeFor4"
                            type="number"
                            step=".01"
                            class="form-control"
                            :min="item.features.find(item => item.name.toLowerCase() === 'gourmet') ? 1 : 0"
                            :required="item.features.find(item => item.name.toLowerCase() === 'gourmet')"/>
                          <div class="input-group-append">
                            <span class="input-group-text">per serving</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isNew && !externalReview" class="col-xl-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Developers</h3>
                  </div>
                  <div class="table-responsive" style="max-height: 25rem; overflow-y: scroll;">
                    <table class="table text-nowrap card-table">
                      <tbody>
                        <tr>
                          <td class="text-left">Created: {{item.created_by}}</td>
                          <td class="text-right">
                            {{item.createdAt ? moment.utc(item.createdAt).local().format('D MMM YYYY HH:mm:ss') : ''}}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Updated: {{item.last_update_by}}</td>
                          <td class="text-right">
                            {{item.updatedAt ? moment.utc(item.updatedAt).local().format('D MMM YYYY HH:mm:ss') : ''}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  v-if="item.status || item.method_view"
                  class="card">
                  <div class="card-header">
                    <h3 class="card-title">Recipe Process Status</h3>
                  </div>

                  <div
                    v-if="item.status"
                    class="table-responsive"
                    style="max-height: 25rem;">
                    <table class="table text-nowrap card-table">
                      <tbody>
                        <tr>
                          <td colspan="100%">
                            <div v-if="item.status === 'published-on-menu'">
                              Published On Menu
                              <template v-if="item.published_at">
                                -
                                <a
                                  v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                                  data-test="link-published-at"
                                  :href="`/schedule-menu?date=${item.published_at}`"
                                  target="_blank"
                                  rel="noreferrer noopener">
                                  {{moment.utc(item.published_at).local().format('D MMM YYYY')}}
                                </a>
                                <span v-else data-test="label-published-at">
                                  {{moment.utc(item.published_at).local().format('D MMM YYYY')}}
                                </span>
                              </template>
                            </div>
                            <select
                              v-else
                              v-model="item.status"
                              :disabled="true"
                              class="form-control custom-select">
                              <option
                                v-for="status in dynamicStatusesList"
                                :key="status.id"
                                :value="status.value">
                                {{status.name}}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr v-if="item.concept_menu_start_date">
                          <td  colspan="100%">
                            In Concept Menu -
                            <a
                              v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                              data-test="link-concept-start-date"
                              :href="`/schedule-menu?date=${item.concept_menu_start_date}`"
                              target="_blank"
                              rel="noreferrer noopener">
                              {{moment.utc(item.concept_menu_start_date).local().format('D MMM YYYY')}}
                            </a>
                            <span v-else data-test="label-concept-start-date">
                              {{moment.utc(item.concept_menu_start_date).local().format('D MMM YYYY')}}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="item.draft_menu_start_date">
                          <td  colspan="100%">
                            In Draft Menu -
                            <a
                              v-if="can(Permissions.MENU_SCHEDULE_VIEW)"
                              data-test="link-draft-start-date"
                              :href="`/schedule-menu?date=${item.draft_menu_start_date}`"
                              target="_blank"
                              rel="noreferrer noopener">
                              {{moment.utc(item.draft_menu_start_date).local().format('D MMM YYYY')}}
                            </a>
                            <span v-else data-test="label-draft-start-date">
                              {{moment.utc(item.draft_menu_start_date).local().format('D MMM YYYY')}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <template v-if="item.method_view">
                    <div class="dropdown-divider"></div>
                    <div class="px-4">
                      <div class="form-group col">
                        <label class="form-label">Recipe method view</label>
                        <div class="custom-control custom-radio">
                          <input
                            v-model="item.method_view"
                            type="radio"
                            class="custom-control-input"
                            value="classic"
                            data-test="radio-recipe-method-classic"
                            id="classicRecipeMethod">
                          <label for="classicRecipeMethod" class="custom-control-label">
                            Classic
                          </label>
                        </div>
                        <div class="custom-control custom-radio ml-4">
                          <input
                            v-model="item.method_view"
                            type="radio"
                            class="custom-control-input"
                            value="bullet"
                            data-test="radio-recipe-method-bullet"
                            id="bulletRecipeMethod">
                          <label for="bulletRecipeMethod" class="custom-control-label">
                            Bullet
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  :class="reviews.equipments || (externalReview && currentReviews.equipments)? 'highlighted' : ''"
                  @click="(externalReview || reviews.equipments) && handleInput('equipments', 'Equipment required', 'checkbox')"
                >
                  <selection-card
                    caption="Equipment required"
                    :items="equipments"
                    :label="value => value.name"
                    :selected="value => item.equipments.some(a => a.id === value.id)"
                    :total-selected="item.equipments.length"
                    :filter="false"
                    :disabled="() => externalReview"
                    @change="toggleArray(item.equipments, $event.item, (a, b) => a.id === b.id)"></selection-card>
                </div>

                <recipe-allergens-card :ingredients="item.ingredients"/>
                <select-customer-profile
                  :recipe-id="item.id"
                  :recipe-profiles="item.customerProfiles"
                />
              </div>

            </div>
            <div class="row row-cards row-deck">
              <div
                :class="[
                  reviews.features || (externalReview && currentReviews.features) ? 'highlighted' : '',
                  canViewRecipeSwaps ? 'col-md-6' : 'col-md-12'
                ]"
              >
                <div @click="(externalReview || reviews.features) && handleInput('features', 'Features', 'checkbox')">
                  <selection-card
                    caption="Features"
                    :items="features"
                    :label="value => value.name"
                    :selected="value => item.features.some(a => a.id === value.id)"
                    :total-selected="item.features.length"
                    :disabled="() => externalReview"
                    @change="toggleArray(item.features, $event.item, (a, b) => a.id === b.id)"></selection-card>
                </div>
              </div>

              <div v-if="canViewRecipeSwaps" class="col-md-6">
                <recipes-swap-list
                  :swaps="availableSwaps"
                  @trigger-add-swap="() => isAddSwapShow = true"
                  @edit="handleEditSwapClick"
                  @delete="removeSwap"/>

                <theme-configuration
                  v-if="can([uiPermissions.RECIPE_SET_THEME, uiPermissions.RECIPE_UNSET_THEME])"
                  :recipe-id="item.id"
                  :recipe-theme="item.theme"
                  :themePhotoDesktop="item.themed_photo_desktop"
                  :themePhotoMobile="item.themed_photo_mobile"
                  @photo-upload-error="handleImageError"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Recipe tags</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="tagGroup in tags"
                        :key="tagGroup.id"
                        class="col-3 relative h-100">
                        <auto-tags
                          v-if="tagGroup.name.toLowerCase().includes('protein')"
                          :type="'Protein'"
                          :tags-list="tagGroup.tags"
                          :selected-tags="select.protein"/>
                        <auto-tags
                          v-if="tagGroup.name.toLowerCase().includes('carb')"
                          :type="'Carb'"
                          :tags-list="tagGroup.tags"
                          :selected-tags="select.carb"/>
                        <template v-if="tagGroup.name.toLowerCase().includes('dish')">
                          <label class="form-label">Dish type</label>
                          <div
                            :class="reviews.dish || (externalReview && currentReviews.dish) ? 'highlighted' : ''"
                            @click="(externalReview || reviews.dish) && handleInput('dish', 'Dish type', 'tags')"
                          >
                            <selectize
                              v-model="select.dish"
                              :disabled="externalReview || reviews.dish"
                              class="form-control custom-select selectized h-100"
                            >
                              <option :value="null">Choose dish type</option>
                              <option
                                v-for="tag in tagGroup.tags"
                                :key="tag.id"
                                :value="tag.id">
                                {{tag.name}}
                              </option>
                            </selectize>
                          </div>
                        </template>
                        <template v-if="tagGroup.name.toLowerCase().includes('geography')">
                          <label class="form-label">Geography</label>
                          <div
                            :class="reviews.geography || (externalReview && currentReviews.geography) ? 'highlighted' : ''"
                            @click="(externalReview || reviews.geography) && handleInput('geography', 'Geography', 'tags')"
                          >
                            <selectize
                              v-model="select.geography"
                              :disabled="externalReview || reviews.geography"
                              class="form-control custom-select selectized h-100"
                            >
                              <option :value="null">Choose geography</option>
                              <option
                                v-for="tag in tagGroup.tags"
                                :key="tag.id"
                                :value="tag.id">
                                {{tag.name}}
                              </option>
                            </selectize>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ingredients tab -->
          <div
            class="tab-pane fade"
            :class="{'show active': tab === 'ingredients'}"
            role="tabpanel">
            <ingredient-form
              :item.sync="item"
              :ingredients="ingredients"
              :reviews="reviews.ingredients"
              :currentReviews="currentReviews.ingredients"
              :externalReview="externalReview"
              :internalReview="internalReview"
              :show-serving-size="recipeCategory === RECIPE_CATEGORY.MARKET"
              @ingredientRemoved="value => removedIngredient(value)"/>
          </div>

          <!-- nutritionals tab -->
          <div
            class="tab-pane fade"
            :class="{'show active': tab === 'nutritionals'}"
            role="tabpanel">
            <nutritional-form :item="item"/>
          </div>

          <!-- method tab -->
          <div
            class="tab-pane fade"
            :class="{'show active': tab === 'method'}"
            role="tabpanel">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Method</h3>
                    <div  class="card-options">
                      <button
                        v-if="showClassicSteps"
                        v-b-tooltip="'Convert to bullet points'"
                        class="btn btn-outline-primary rounded mr-2"
                        :disabled="disableFormInputs"
                        @click.prevent="convertToBulletPoints">
                        <i class="fe fe-arrow-right"></i>
                        <i class="fe fe-align-left"></i>
                      </button>
                      <label data-test="label-classic-step" class="custom-switch mr-2">
                        <input
                          v-model="showClassicSteps"
                          data-test="toggle-classic-step"
                          type="checkbox"
                          ignore-disable
                          class="custom-switch-input">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">Classic</span>
                      </label>
                      <template v-if="externalReview">
                        <review-submit-buttons
                          @draft="saveReview('pending')"
                          @submit="saveReview('completed')"
                        >
                        </review-submit-buttons>
                      </template>
                      <template v-else>
                        <button
                          v-b-tooltip="'Step placeholders'"
                          class="btn btn-secondary"
                          @click.prevent="showPlaceholderModal = true">
                          <i class="fe fe-code"></i>
                        </button>
                        <button
                          v-b-tooltip="'Ingredients list'"
                          class="btn btn-secondary ml-2"
                          @click.prevent="showIngredientModal = true">
                          <i class="fe fe-list"></i>
                        </button>
                      </template>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      v-for="(step, index) in item.steps"
                      :key="step.step"
                      class="form-group row d-flex mb-5">
                      <label class="col-1 col-form-label">{{step.step}}</label>
                      <div
                        class="col-9 d-flex flex-column gap-8"
                        :class="{'rounded-12 border pt-4 border-warning': counterCharacters(step).limitWarning}">
                        <div class="form-row">
                          <div
                            class="w-100"
                            :class="reviews[`steps.${index}.name`] || (externalReview && currentReviews[`steps.${index}.name`]) ? 'highlighted' : ''"
                            @click="(externalReview || reviews[`steps.${index}.name`]) && handleInput(`steps.${index}.name`, `Step name ${step.step}`, 'text')"
                          >
                            <input
                              v-model="step.name"
                              :readonly="externalReview"
                              type="text"
                              class="form-control"
                              placeholder="Action"/>
                          </div>
                        </div>
                        <div v-if="showClassicSteps" class="form-row">
                          <div
                            class="w-100"
                            @click="(externalReview || reviews[`steps.${index}.instructions`]) && handleInput(`steps.${index}.instructions`, `Instuctions ${step.step}`, 'text')"
                          >
                            <div
                              v-if="disableFormInputs"
                              v-html="html(step.instructions)"
                              :contenteditable="false"
                              class="disabled-step"
                              :class="{'invalid': invalidStep(step) || !validatePlaceholders(step.instructions)}"/>
                            <div
                              v-else
                              v-html="html(step.instructions)"
                              class="instruction p-3 border"
                              :class="[
                                {'invalid': invalidStep(step) || !validatePlaceholders(step.instructions)},
                                {
                                  'highlighted': reviews[`steps.${index}.instructions`] || (externalReview && currentReviews[`steps.${index}.instructions`])
                                }
                              ]"
                              :contenteditable="externalReview ? false : true"
                              @keydown="(event) => updateStep(event, step)"
                              @input="(event) => updateStep(event, step)"
                            >
                            </div>
                          </div>
                        </div>
                        <template v-else>
                          <draggable
                            v-model="step.instructions_list"
                            :options="{handle: '.fe-move'}"
                            element="div"
                            ghost-class="ghost"
                            class="form-row d-flex flex-column gap-6 ml-2">
                            <div
                              v-for="(instructions, listIndex) in step.instructions_list"
                              :key="listIndex"
                              class="d-flex w-100 gap-8 align-items-center">
                              <div class="d-flex justify-content-center align-items-center">
                                <i class="fe fe-move cursor-move"></i>
                              </div>

                              <div
                                class="flex-1 border d-flex align-items-center"
                                :class="[
                                  {'invalid': invalidStep(step) || !validatePlaceholders(instructions)},
                                  {'disabled-step': disableFormInputs}
                                ]"
                                @click="(externalReview || reviews[`steps.${index}.instructions_list.${listIndex}`]) && handleInput(`steps.${index}.instructions_list.${listIndex}`, `Instructions ${step.step}`, 'text')"
                              >
                                <div class="flex-1">
                                  <div
                                    v-if="disableFormInputs"
                                    v-html="html(instructions)"
                                    :contenteditable="false">
                                  </div>
                                  <div
                                    v-else
                                    v-html="html(instructions)"
                                    class="instruction p-2 border-right"
                                    :class="[
                                      {
                                        'highlighted': reviews[`steps.${index}.instructions_list.${listIndex}`] || (externalReview && currentReviews[`steps.${index}.instructions_list.${listIndex}`])
                                      }
                                    ]"
                                    :contenteditable="!externalReview"
                                    :id="`instructions_${index}_${listIndex}`"
                                    @keydown="(event) => updateStep(event, step, listIndex)"
                                    @input="(event) => updateStep(event, step, listIndex)"
                                    @keyup.enter.prevent="addStep(step, index, listIndex)"
                                  >
                                  </div>
                                </div>
                                <small
                                  v-if="!disableFormInputs"
                                  class="text-muted mx-2 w-3 text-center">
                                  {{counterCharacters(step).totalRowsForItem[listIndex]}}
                                </small>
                              </div>

                              <button
                                v-if="(step.instructions_list.length || step.instructions_list[0]) && !externalReview && !disableFormInputs"
                                type="button"
                                class="btn btn-danger btn-sm w-6 h-6"
                                @click.prevent="removeStep(step, listIndex)">
                                <i class="fe fe-x"></i>
                              </button>
                            </div>
                          </draggable>
                          <div class="ml-4 d-flex align-items-center">
                            <div v-if="!disableFormInputs && !externalReview" class="d-flex align-items-center">
                              <button
                                type="button"
                                class="btn btn-primary w-fit btn-sm"
                                @click.prevent="addStep(step, index, step.instructions_list.length - 1)">
                                <i class="fe fe-plus"></i>
                                Add
                              </button>
                              <label
                                data-test="toggle-active"
                                class="custom-switch ml-2">
                                <input
                                  v-model="step.showTip"
                                  type="checkbox"
                                  class="custom-switch-input"
                                  @click="handleToggleTip(step, index, $event)">
                                <span class="custom-switch-indicator"></span>
                                <span class="custom-switch-description">Tip</span>
                              </label>
                            </div>
                            <div
                              v-if="!disableFormInputs"
                              :class="{'invalid': invalidStep(step)}"
                              class="text-right w-100">
                              <small>{{counterCharacters(step).stepRows}}/{{MAX_ROWS_ALLOWED}}</small>
                            </div>
                          </div>
                        </template>

                        <div v-if="step.showTip || showClassicSteps || disableFormInputs || externalReview" class="form-row">
                          <div
                            class="w-100"
                            :class="reviews[`steps.${index}.tip`] || (externalReview && currentReviews[`steps.${index}.tip`]) ? 'highlighted' : ''"
                            @click="(externalReview || reviews[`steps.${index}.tip`]) && handleInput(`steps.${index}.tip`, `Tip ${step.step}`, 'text')"
                          >
                            <input
                              v-model="step.tip"
                              :class="{'invalid': invalidStep(step) || !validatePlaceholders(step.tip)}"
                              type="text"
                              class="form-control"
                              placeholder="Tip"
                              :readonly="externalReview"
                              :id="'tips_' + index"
                              @keydown="(event) => checkCharacters(event, step)"
                              @input="(event) => checkCharacters(event, step)"
                            />
                          </div>
                          <div :class="{'invalid': invalidStep(step)}" class="text-right w-100">
                            <small v-if="showClassicSteps">
                              {{counterCharacters(step).totalCharacters}}/{{MAX_CHARS_FOR_CLASSIC}}
                            </small>
                            <small v-else>
                              {{counterCharacters(step).totalRows}}/{{MAX_ROWS_ALLOWED}}
                            </small>
                          </div>
                        </div>

                        <div v-if="counterCharacters(step).limitWarning" class="alert alert-icon alert-warning alert-dismissible mt-2">
                          <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
                          <strong>Warning!</strong> The number of bullet points exceeds the limit of 10 lines, and a tip has been added. Please ensure that this step does not overlap in the recipe card.
                        </div>
                      </div>
                      <div class="col-2 d-flex align-items-center justify-content-center">
                        <image-input
                          v-model="step.photo"
                          :tags="['recipe', 'step']"
                          :thumbnail-options="{height: 160, width: 160, crop: 'fill'}"
                          landscape-only
                          class="avatar avatar-xxl"
                          @error="handleImageError"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- feedback tab -->
          <div
            class="tab-pane fade"
            :class="{'show active': tab === 'feedback'}"
            role="tabpanel">
            <div class="row">

              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Rating</h3>

                    <div class="card-options">
                      <div class="small text-muted">#{{item.id}} {{item.name}}</div>
                    </div>
                  </div>
                  <div class="card-body">
                    This recipe has been rated by {{item.feedbackCount}} customers, with an average of {{numeral(item.averageRating).format('0.0')}} out of 5.
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">
                      Latest 50 comments
                    </h3>
                  </div>
                  <div class="table-responsive">
                    <table class="table card-table table-vcenter text-nowrap">
                      <thead>
                        <tr>
                          <th>Rating</th>
                          <th>Comment</th>
                          <th>Order</th>
                        </tr>
                      </thead>
                      <tbody v-for="group in groupedFeedback" :key="group.week">
                        <tr>
                          <th colspan="2">{{weekName(group.week)}}</th>
                        </tr>
                        <tr v-for="feedback in group.data" :key="feedback.id">
                          <td>{{feedback.rating}}</td>
                          <td>{{feedback.comment}}</td>
                          <td>
                            <a
                              v-if="feedback.orderId"
                              :href="`/orders/${feedback.orderId}`"
                              target="_blank">order (#{{feedback.orderId}})</a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="item.feedback.length === 0">
                        <tr>
                          <td colspan="3">The recipe does not have any feedback.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
            <!-- wrap those components in template so they won't load until the tab is clicked -->
            <template v-if="item.id && tab === 'feedback'">
              <div v-if="can(uiPermissions.RECIPES_NOTES_VIEW)" class="row">
                <div class="col">
                  <notes-form :recipe-id="item.id"/>
                </div>
              </div>
              <div  class="row">
                <div class="col">
                  <weekly-menu-stats :recipe-id="item.id"/>
                </div>
              </div>
            </template>
          </div>

          <!-- market tab -->
          <div
            v-if="recipeCategory === RECIPE_CATEGORY.MARKET"
            class="tab-pane fade"
            :class="{'show active': tab === 'market'}"
            role="tabpanel">
            <div class="row">

              <div class="col">
                <MarketRecipeForm
                  :recipe-id="item.id"
                  :market-item="item.market_item"
                  :is-new="isNew"
                  @updated="refreshData"
                  @update:marketItem="setMarketItem"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div v-if="externalReview" class="card">
                <div class="card-footer d-flex justify-content-end">
                  <button
                    v-b-tooltip="'Check proportionality between 2P, 3P and 4P boxes for all the ingredients'"
                    class="btn btn-secondary mr-2"
                    @click.prevent="showProportionality = true">
                    Proportionality
                  </button>
                  <review-submit-buttons
                    @draft="saveReview('pending')"
                    @submit="saveReview('completed')"
                  >
                  </review-submit-buttons>
                </div>
              </div>
              <edit-footer
                v-else
                :dirty="dirty"
                :hide-delete="true"
                entity="recipe"
                :isNew="isNew"
                :submitting="submitting"
              >
                <button
                  v-b-tooltip="'Check proportionality between 2P, 3P and 4P boxes for all the ingredients'"
                  class="btn btn-secondary mr-2"
                  @click.prevent="showProportionality = true">
                  Proportionality
                </button>

                <button
                  v-if="can(uiPermissions.RECIPES_THUMBNAIL_UPDATE) && item.photo"
                  v-b-tooltip="'Customize and crop mobile photo thumbnail'"
                  class="btn btn-secondary mr-2"
                  @click.prevent="showMobileThumbnail = true">
                  Mobile view
                </button>

                <download-recipe-card
                  v-if="can(uiPermissions.RECIPES_CARD_DOWNLOAD) && item.id"
                  :disabled="submitting"
                  :url="item.card"
                  :recipeId="item.id"
                />

                <quick-preview-recipe
                  v-if="can(uiPermissions.RECIPES_CARD_VIEW) && item.card"
                  :url="item.card"/>
              </edit-footer>
            </div>

          </div>
        </div>
      </form>

      <b-modal
        v-model="showIngredientModal"
        title="Ingredient checklist"
        size="md"
        hide-footer
        id="modal-ingredients">
        <template slot="modal-header-close"><wbr></template>
        <div style="max-height: 40rem; overflow-y: auto;">
          <table class="table card-table">
            <tbody>
              <tr v-for="item in uniqueIngredients" :key="item">
                <td>{{item}}</td>
                <td class="w-1">
                  <span
                    class="badge"
                    :class="{
                      'badge-success': usedCount(item),
                      'badge-default': !usedCount(item)
                    }">
                    {{usedCount(item)}}
                  </span>
                </td>
              </tr>
              <tr v-if="!item.ingredients">
                <td>No ingredients found.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>

      <b-modal
        v-model="showPlaceholderModal"
        title="How to use special placeholders"
        size="small"
        hide-footer
        id="modal-placeholder">
        <template slot="modal-header-close"><wbr></template>
        <div style="max-height: 40rem; overflow-y: auto;">
          <table class="table card-table">
            <thead>
              <tr>
                <th>Placeholder</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Manual bolding</td>
                <td>[b]insert text here[/b]</td>
              </tr>
              <tr>
                <td>Ignore bolding</td>
                <td>[i]insert text here[/i]</td>
              </tr>
              <tr>
                <td>Spicy bolding</td>
                <td>(spicy!)</td>
              </tr>
              <tr>
                <td>Proportions</td>
                <td>{2p/3p/4p}</td>
              </tr>
            </tbody>
          </table>
          <div class="small note mt-4">*The placeholder tags ([b], [/b], [i], [/i]) will not show up in the PDF cards and the website's recipe page.</div>
          <div class="small note mt-4">*The proportions will be used according to the number of people in the PDF cards and the website's recipe page.</div>
        </div>
      </b-modal>

      <b-modal
        v-model="showRemoveIngredientModal"
        centered
        title="Ingredient removed"
        size="small"
        hide-footer
        id="modal-placeholder">
        <template slot="modal-header-close"><wbr></template>
        <p>Make sure to remove <strong>{{removedIngedient}}</strong> that's used {{removedIngredientCount}} times in the method!</p>
      </b-modal>

      <b-modal
        v-model="showPenultimateGateModal"
        centered
        size="small"
        hide-footer
        id="modal-gate1">
        <template slot="modal-header-close"><wbr></template>
        <h3 class="text-center">Warning you are about to edit a scheduled recipe!</h3>
      </b-modal>

      <b-modal
        v-model="showFinalGateModal"
        centered
        size="small"
        hide-footer
        id="modal-gate2">
        <template slot="modal-header-close"><wbr></template>
        <h3 class="text-center">You cannot edit this recipes, as it is published!</h3>
      </b-modal>

      <b-modal
        v-model="showCannotEditDraftRecipeModal"
        centered
        size="small"
        ok-title="Proceed"
        cancel-title="Back"
        id="modal-cannot-edit"
        @cancel="goBack"
      >
        <template slot="modal-header-close"><wbr></template>
        <h3 class="text-center">You cannot edit this recipe, as it is ready to be drafted</h3>
        <p class="text-center">If you want to proceed with edits the recipe will revert to in development</p>
      </b-modal>

      <thumbnail-cropper
        v-if="item.photo"
        :recipe-id="item.id"
        :photo="item.photo"
        :thumbnail="item.thumbnail"
        :show="showMobileThumbnail"
        @close="showMobileThumbnail = false"
        @show="value => showMobileThumbnail = value"
        @cropped="setMobileThumbnail"
      />

      <recipes-add-swap-modal
        :is-show="isAddSwapShow"
        :recipe-id="item.id"
        @show="value => isAddSwapShow = value"
        @add-swap="selectSwapRecipe"/>

      <recipes-swap-ingredient-modal
        :key="isSwapIngredientShow"
        :is-show="isSwapIngredientShow"
        :swap-recipe="selectedSwapRecipe"
        @close="handleSwapIngredientClose"
        @apply="applySwapIngredients"
      />

      <proportionality-modal
        :is-show="showProportionality"
        :item.sync="item"
        @show="value => showProportionality = value"/>
    </div>
  </dimmer>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import ImageInput from '@/components/ImageInput';
import SelectionCard from '@/components/SelectionCard';
import RecipeAllergensCard from '@/components/RecipeAllergensCard';
import IngredientForm from './forms/IngredientForm';
import NutritionalForm from './forms/NutritionalForm';
import edit from '@/mixins/edit';
import highlighter from '@/mixins/highlighter';
import {recipeEquipments, recipeFeatures, recipeProteins, recipes, recipeReviews, tags, recipePendingReview, recipeSwaps} from '@/services';
import {recipeStatusesList} from '@/views/recipes/recipe-statuses';
import {startCase} from 'lodash';
import ReviewContainer from '@/components/formReview/ReviewContainer';
import ReviewEditor from '@/components/formReview/ReviewEditor';
import ReviewSubmitButtons from '@/components/formReview/ReviewSubmitButtons';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import {mapState} from 'vuex';
import QuickPreviewRecipe from '@/components/QuickPreviewRecipe';
import DownloadRecipeCard from '@/components/DownloadRecipeCard';
import AutoTags from '@/views/recipes/AutoTags';
import WeeklyMenuStats from '@/views/recipes/components/WeeklyMenuStats';
import ThumbnailCropper from '@/views/recipes/modals/ThumbnailCropper';
import RecipesSwapList from '@/components/view/recipes/recipes-swap-list';
import RecipesAddSwapModal from '@/components/view/recipes/recipes-add-swap-modal';
import RecipesSwapIngredientModal from '@/components/view/recipes/recipes-swap-ingredient-modal';
import ThemeConfiguration from '@/views/recipes/components/ThemeConfiguration';
import SelectCustomerProfile from '@/views/recipes/components/SelectCustomerProfile';
import NotesForm from '@/views/recipes/forms/NotesForm';
import Permissions from '@hellochef/shared-js/enums/Permissions';
import ProportionalityModal from '@/views/recipes/modals/ProportionalityModal';
import MarketRecipeForm from '@/views/recipes/forms/MarketRecipeForm';
import Vue from 'vue';
import draggable from 'vuedraggable';

const DEFAULT_NUTRITION_VALUE = {
  carbohydrates: 0,
  energyKCal: 0,
  energyKJ: 0,
  fat: 0,
  fibre: 0,
  protein: 0,
  salt: 0,
  saturates: 0,
  sugarCarbohydrates: 0,
  weight: 100,
};

const MAX_CHARS_FOR_CLASSIC = 521;
const MAX_CHARS_PER_ROW = 52;
const MAX_ROWS_ALLOWED = 13;
const MAX_ROWS_WITH_TIPS = 10;
const NO_OF_PEOPLE_SPLIT = 2;

const RECIPE_CATEGORY = {
  DINNER: 'dinner',
  MARKET: 'market',
};

export default {
  name: 'RecipeEdit',
  components: {
    NotesForm,
    ThemeConfiguration,
    SelectCustomerProfile,
    ThumbnailCropper,
    WeeklyMenuStats,
    AutoTags,
    QuickPreviewRecipe,
    BModal,
    EditFooter,
    EditHeader,
    ImageInput,
    IngredientForm,
    NutritionalForm,
    SelectionCard,
    RecipeAllergensCard,
    ReviewContainer,
    ReviewEditor,
    ReviewSubmitButtons,
    RecipesSwapList,
    RecipesAddSwapModal,
    RecipesSwapIngredientModal,
    ProportionalityModal,
    DownloadRecipeCard,
    MarketRecipeForm,
    draggable,
  },
  mixins: [
    edit,
    highlighter,
  ],
  beforeRouteLeave(to, from, next) {
    if (!this.dirty) next();
    if (this.dirty && window.confirm('You have unsaved changes, Are you sure you want to leave?')) {
      window.removeEventListener('beforeunload', this.preventNav);
      next();
    }
    else {
      next(false);
    }
  },
  data() {
    return {
      recipeCategory: RECIPE_CATEGORY.DINNER,
      showMobileThumbnail: false,
      equipments: [],
      features: [],
      hasPendingIngredients: false,
      ingredients: [],
      item: {
        averageRating: 0,
        feedbackCount: 0,
        cookingTime: 30,
        prep_time: 0,
        equipments: [],
        customerProfiles: [],
        features: [],
        feedback: [],
        gourmetSurchargeFor2: 0,
        gourmetSurchargeFor3: 0,
        gourmetSurchargeFor4: 0,
        ingredients: [],
        introduction: '',
        is_in_drafted_menu: false,
        is_in_published_menu: false,
        is_in_scheduled_menu: false,
        mainProtein: null,
        nutritions: [],
        photo: null,
        themed_photo_desktop: null,
        themed_photo_mobile: null,
        revisions: [],
        last_update_by: null,
        created_by: '',
        steps: new Array(6).fill(null).map((nothing, index) => ({
          instructions: '',
          instructions_list: [''],
          name: '',
          step: index + 1,
          tip: '',
        })),
        status: '',
        tags: [],
        weeklyClassic: false,
        updatedAt: '',
        createdAt: '',
        serving_size: null,
        method_view: '',
        market_item: null,
      },
      proteins: [],
      removedIngedient: '',
      removedIngredientCount: 0,
      select: {

        carb: [],
        dish: [],
        geography: [],
        protein: [],
      },
      selected: 1,
      showFinalGateModal: false,
      showIngredientModal: false,
      showPenultimateGateModal: false,
      showPlaceholderModal: false,
      showRemoveIngredientModal: false,
      showCannotEditDraftRecipeModal: false,
      successAddArticle: 'Successfully requested new article',
      successAddSubRecipe: 'Successfully requested new sub-recipe',
      tab: 'general',
      tags: null,
      withRelations: [
        'ingredients.articlesForRecipes.nutritions',
        'ingredients.allergens',
        'ingredients.category',
        'ingredients.tags.category',
        'mainProtein',
        'steps.photo',
        'tags.category',
        'equipments',
        'features',
        'feedback',
        'customerProfiles',
        'thumbnail',
        'swaps',
        'theme',
        'themedPhotoDesktop',
        'themedPhotoMobile',
        'market_recipe',
      ],
      updated: false,
      newReview: null,
      peerReviewEditMode: false,
      reviewModalKey: null,
      reviews: {
        ingredients: {},
      },
      currentReviews: {
        ingredients: {},
      },
      swaps: [],
      isAddSwapShow: false,
      isSwapIngredientShow: false,
      isSwapEdit: false,
      selectedSwapRecipe: {},
      recipeSwapError: null,
      showClassicSteps: false,
      showProportionality: false,
      MAX_ROWS_ALLOWED,
      MAX_CHARS_FOR_CLASSIC,
      RECIPE_CATEGORY,
    };
  },
  computed: {
    ...mapState({
      user: state => (state.auth.customer || {}).user,
    }),
    externalReview: {
      get() {
        return (this.item.status === 'in-review' && this.can(Permissions.RECIPES_EXTERNAL_REVIEW)) || this.peerReviewEditMode;
      },
      set(newValue) {
        this.peerReviewEditMode = newValue;
      },
    },
    internalReview() {
      return (this.item.status === 'in-review' || this.item.status === 'peer-test') && this.can(Permissions.RECIPES_INTERNAL_REVIEW);
    },
    isPeerReview() {
      return this.item.status === 'peer-test' && this.can(Permissions.RECIPES_PEER_REVIEW);
    },
    currentReview() {
      if (this.reviewModalKey) {
        return get(this.reviews, this.reviewModalKey);
      }

      return {};
    },
    statusName() {
      return startCase(this.item.status);
    },
    currentStatusId() {
      return recipeStatusesList.find(it => it.value === this.item.status).id;
    },
    dynamicStatusesList() {
      const list = recipeStatusesList.filter(it => it.id <= this.currentStatusId || it.id === this.currentStatusId + 1);
      // temporarily, for in-development status, add ready-to-drafted status also
      if (this.currentStatusId === 1) {
        list.push(recipeStatusesList[4]);
      }
      return list;
    },
    groupedFeedback() {
      return Object.entries(this.item.feedback.reduce((a, data) => {
        (a[data.week] = a[data.week] || []).push(data);

        return a;
      }, {})).map(item => {
        return {

          data: item[1],
          week: item[0],
        };
      }) || [];
    },
    mainProtein: {
      get() { return this.item.mainProtein ? this.proteins.find(x => x.id === this.item.mainProtein.id) : null; },
      set(newValue) { this.item.mainProtein = newValue; },
    },
    route() {
      return `/recipes/${this.item.id}`;
    },
    uniqueIngredients() {
      return [...new Set(this.item.ingredients.flatMap(item => item.name))];
    },
    hasMissingPortions() {
      return !this.isNew && this.item.ingredients.some(ingredient => Object.keys(ingredient.people).length < 3);
    },
    counterCharacters() {
      return ({tip, instructions, instructions_list}) => {
        // will match opening of custom bold tags
        const boldOpen = new RegExp(/\[b\]/, 'g');
        // will match closing of custom bold tags
        const boldClose = new RegExp(/\[\/b\]/, 'g');
        // will match opening of custom ignore bold tags
        const cursiveOpen = new RegExp(/\[i\]/, 'g');
        // will match closing of custom ignore bold tags
        const cursiveClose = new RegExp(/\[\/i\]/, 'g');
        // will match anything between { and } including the brackets for example {2/3/4}
        const proportionsIgnore = new RegExp(/(?<=\{)(.*?)(?=\})/, 'g');

        // for classic steps
        if (this.showClassicSteps) {
          const ignoreTags = instructions ? instructions.replaceAll(boldOpen, '').replaceAll(boldClose, '').replaceAll(cursiveOpen, '').replaceAll(cursiveClose, '') : '';
          return {
            totalCharacters: tip ? tip.length + ignoreTags.length : ignoreTags.length,
          };
        }

        // for bullet points steps
        let totalCharacters = tip ? tip.length : 0;
        let totalRows = 0;
        let stepRows = 0;
        const totalRowsForItem = [];

        instructions_list.forEach((item, index) => {
          const ignoreTags = item
            ? item
              .replaceAll(boldOpen, '')
              .replaceAll(boldClose, '')
              .replaceAll(cursiveOpen, '')
              .replaceAll(cursiveClose, '')
              .replaceAll(proportionsIgnore, '-') // we will replace the proportions with a dash so we can count the characters to 3 ({-})
            : '';

          const charactersInItem = ignoreTags.length;
          // Calculate how many rows this item will consume
          const rowsForItem = Math.ceil(charactersInItem / MAX_CHARS_PER_ROW);
          // Add the number of rows to the current rows
          totalRows += rowsForItem;
          stepRows += rowsForItem;
          totalCharacters += charactersInItem;
          totalRowsForItem[index] = rowsForItem;
        });

        if (tip) {
          const rowsForItem = Math.ceil(tip.length / MAX_CHARS_PER_ROW);
          // Add the number of rows to the current rows
          totalRows += rowsForItem;
        }

        const limitWarning = stepRows > MAX_ROWS_WITH_TIPS && totalRows > stepRows;

        return {
          totalCharacters,
          totalRows,
          stepRows,
          totalRowsForItem,
          limitWarning,
        };
      };
    },
    availableSwaps() {
      return this.swaps?.map(swap => ({
        id: swap.swappable_recipe.recipe_id,
        name: swap.swappable_recipe.recipe_name,
        photo: swap.swappable_recipe.recipe_photo,
        swapFromId: swap.swappable_recipe.swap_id,
        swapFrom: swap.swappable_recipe.swap_text,
        swapToId: swap.current_recipe.swap_id,
        swapTo: swap.current_recipe.swap_text,
      }));
    },
    isTitleCharLimit() {
      return (this.item?.cardName1 || '').length > 50;
    },
    isSubtitleCharLimit() {
      return (this.item?.cardName2 || '').length > 44;
    },
    canViewRecipeSwaps() {
      return this.can(Permissions.RECIPES_SWAPS_VIEW) && !this.isNew;
    },
    disableFormInputs() {
      if (this.tab === 'nutritionals') {
        return false;
      }
      return !this.can(Permissions.RECIPES_UPDATE) || this.item.archived || this.item.is_in_published_menu;
    },
  },
  watch: {
    'item.ingredients': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.length) {
          // when ingredients are changed we update the protein and carbs tags
          let tags = newVal.flatMap(ingredient => {
            return ingredient.tags;
          });
          tags = tags.filter((a, i) => tags.findIndex(s => a?.id === s?.id) === i);
          this.select.carb = tags.filter(item => item.category.name.toLowerCase().includes('carb')).map(item => item.id);
          this.select.protein = tags.filter(item => item.category.name.toLowerCase().includes('protein')).map(item => item.id);
          this.hasPendingIngredients = newVal.some(ingredient => ingredient.articles.length && ingredient.articles.some(data => data.status === 'draft'));
        }
      },
    },
    'item.photo'(newValue, oldValue) {
      // reset thumbnail preview when main photo is changed
      if (newValue?.id !== oldValue?.id && oldValue !== null) {
        this.item.thumbnail = null;
      }
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  created() {
    recipeEquipments.getByParameters().then(result => this.equipments = result.items);
    recipeFeatures.getByParameters().then(result => this.features = result.items);
    recipeProteins.getByParameters().then(result => this.proteins = result.items);
    tags.getGroupedTags().then(result => this.tags = result.filter(item => item.tags.length));
  },
  mounted() {
    if (this.$route.query.view) { this.tab = this.$route.query.view; }

    if (this.$route.query.category) {
      this.recipeCategory = this.$route.query.category;
    }

    // reset dirty after mount so it won't tell the user there are un saved changes when they land on recipes page.
    this.forceDirty = false;
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    async refreshData() {
      await this.reset();
    },
    setMarketItem(data) {
      this.item.market_item = data;
    },
    setMobileThumbnail(croppedImage) {
      this.item.thumbnail = croppedImage;
    },
    _get(obj, key) {
      return get(obj, key);
    },
    goBack() {
      this.$router.back();
    },
    acceptReview(review) {
      if (['dish', 'geography'].includes(review.key)) {
        this.select[review.key] = review.changes;
      }
      else {
        set(this.item, review.key, review.changes);
      }
      delete this.reviews[review.key];
      this.reviewModalKey = null;
    },
    rejectReview(review) {
      // check if review exists in reviews object
      if (!this.reviews[review.key]) {
        this.reviewModalKey = null;
        return;
      }

      this.reviews[review.key] = this.reviews[review.key].filter(({reviewer_id}) => review.reviewer_id !== reviewer_id);

      if (this.reviews[review.key].length === 0) {
        delete this.reviews[review.key];
        this.reviewModalKey = null;
      }
    },
    setNewIngredientsFromReview() {
      const groups = [];

      this.item.ingredients.forEach(({group}) => {
        if (!groups.includes(group)) {
          groups.push(group);
        }
      });

      Object.values(this.reviews.ingredients)
        .flat()
        .filter(review => review.action === 'new')
        .forEach(({changes, reviewer_id}) => {
          this.item.ingredients.push(
            {...changes, reviewer_id, group: groups[changes.group]},
          );
        });

      let index = 1;

      this.item.ingredients.sort((a, b) => a.index - b.index).forEach(ingredient => {
        ingredient.index = index;
        index++;
      });
    },
    handleInput(key, fieldName, type = 'text', list = []) {
      // if field does not exist, fill the content
      if (this.externalReview) {
        if (this.currentReviews[key]) {
          this.newReview = cloneDeep(this.currentReviews[key]);
        }
        else {
          this.newReview = {
            key,
            field_name: fieldName,
            type,
            notes: '',
          };

          const {newList, changes} = this.getInput(key, type, list);
          this.newReview.changes = changes;
          this.newReview.list = newList;
        }
      }
      else {
        this.reviewModalKey = key;
        if (this.reviews[key] && this.reviews[key][0] && !this.reviews[key][0].list) {
          const {newList} = this.getInput(key, type, list);
          this.reviews[key].map(review => {
            review.list = newList;
            return review;
          });
        }
      }
    },
    getInput(key, type, list = []) {
      let changes = null;

      switch (type) {
      case 'tags': {
        const category = find(this.tags, tag => tag.name.toLowerCase().includes(key));
        changes = this.select[key];
        list = category?.tags;
        break;
      }
      case 'select': {
        changes = this[key];
        break;
      }
      default: {
        changes = get(this.item, key);
        list = get(this, key);
      }
      }

      return {changes, newList: list};
    },
    addNewReview({changes, notes}) {
      const input = this.getInput(this.newReview.key, this.newReview.type);

      if (!Array.isArray(changes)) {
        changes = !isNaN(changes) ? Number(changes) : changes;
      }

      if (this.newReview && (!isEqual(changes, input.changes) || notes !== '')) {
        this.currentReviews[this.newReview.key] = {...this.newReview, changes, notes};
      }
      else {
        delete this.currentReviews[this.newReview.key];
      }

      this.newReview = null;
    },
    async saveReview(status) {
      try {
        await recipeReviews.post({
          recipe_id: this.$route.params.id,
          changes: this.currentReviews,
          status,
        });

        if (status === 'completed') {
          this.$router.push('/recipes');
        }
      }
      catch (e) {
        this.$toasted.error('An error has occured. Please try again later');
      }
    },
    invalidStep(step) {
      // for classic steps
      if (this.showClassicSteps) {
        return this.counterCharacters(step).totalCharacters > MAX_CHARS_FOR_CLASSIC;
      }

      // for bullet points steps
      const {totalRows} = this.counterCharacters(step);
      return (totalRows > MAX_ROWS_ALLOWED);
    },
    async fetchData(id) {
      const params = {
        with: this.withRelations.join(','),
        with_ingredients: 1,
        with_dynamic_features: 1,
        with_nutrition: 1,
        with_count: 'feedback',
        createdBy: 1,
        lastUpdateBy: 1,
      };

      return recipes.getById(id, {params});
    },
    handleImageError(ex) {
      this.invalid = true;
      this.invalidMessage = ex.message;

      window.scrollTo(0, 0);
    },
    removedIngredient(value) {
      const used = this.usedCount(value.name);

      if (used) {
        this.removedIngedient = value.name;
        this.removedIngredientCount = used;
        this.showRemoveIngredientModal = true;
      }
    },
    restoreCursor(containerEl, savedSel) {
      if (window.getSelection && document.createRange) {
        let charIndex = 0;
        const range = document.createRange();

        range.setStart(containerEl, 0);
        range.collapse(true);
        const nodeStack = [containerEl];
        let node;
        let foundStart = false;
        let stop = false;

        while (!stop && (node = nodeStack.pop())) {
          if (node.nodeType === 3) {
            const nextCharIndex = charIndex + node.length;

            if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
              range.setStart(node, savedSel.start - charIndex);
              foundStart = true;
            }
            if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
              range.setEnd(node, savedSel.end - charIndex);
              stop = true;
            }

            charIndex = nextCharIndex;
          }
          else {
            let i = node.childNodes.length;

            while (i--) {
              nodeStack.push(node.childNodes[i]);
            }
          }
        }

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
      else if (document.selection) {
        const textRange = document.body.createTextRange();

        textRange.moveToElementText(containerEl);
        textRange.collapse(true);
        textRange.moveEnd('character', savedSel.end);
        textRange.moveStart('character', savedSel.start);
        textRange.select();
      }

      return false;
    },
    saveCursor(containerEl) {
      if (window.getSelection && document.createRange) {
        const range = window.getSelection().getRangeAt(0);
        const preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(containerEl);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        return {
          end: start + range.toString().length,
          start: start,
        };
      }
      else if (document.selection) {
        const selectedTextRange = document.selection.createRange();
        const preSelectionTextRange = document.body.createTextRange();

        preSelectionTextRange.moveToElementText(containerEl);
        preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange);

        const start = preSelectionTextRange.text.length;

        return {

          end: start + selectedTextRange.text.length,
          start: start,
        };
      }

      return false;
    },
    submitData(item) {
      const ingredientsPayload = [];

      // format ingredients
      item.ingredients.filter(ingredient => ingredient.type !== 'new')
        .forEach(ingredient => {
          Object.keys(ingredient.people).forEach(people => {
            const peopleData = ingredient.people[people];

            ingredientsPayload.push({
              group: ingredient.group,
              index: ingredient.index,
              main_ingredient_id: ingredient.id,
              measured_in_recipe_by: ingredient.measured_in_recipe_by,
              people,
              portion_size: peopleData.portion_size,
              portion_size_uom_id: (peopleData.portion_size_uom || {}).id,
              quantity: peopleData.quantity,
            });
          });
        });

      const payload = Object.assign({}, item, {
        equipments: item.equipments.map(x => x.id),
        features: item.features.map(x => x.id),
        gourmetSurchargeFor2: item.gourmetSurchargeFor2,
        gourmetSurchargeFor3: item.gourmetSurchargeFor3,
        gourmetSurchargeFor4: item.gourmetSurchargeFor4,
        ingredients: ingredientsPayload,
        mainProtein: item.mainProtein ? item.mainProtein.id : null,
        nutritions: {},
        photo: item.photo ? item.photo.id : null,
        steps: item.steps.filter(step => step.name || step.instructions || step.tip || step.photo).map(step => Object.assign(step, {
          photo: step.photo?.id ?? step.photo ?? null,
          instructions_list: step.instructions_list.filter(item => item && item.trim()),
        })),
        tags: Object.values(this.select).flat(),
        weeklyClassic: item.weeklyClassic,
        serving_size: item.serving_size,
      });

      return recipes.saveOrUpdate(payload, {
        params: {
          with: this.withRelations.join(','),
          with_ingredients: 1,
          with_dynamic_features: 1,
          with_nutrition: 1,
          createdBy: 1,
          lastUpdateBy: 1,
        },
      });
    },
    submitRevision() {
      this.item.id = null;

      this.submit();
    },
    transformData(result) {
      // show classic step if the recipe does not have bullet points steps or new recipes
      this.showClassicSteps = result.item.steps.length && !result.item.steps.some(step => step.instructions_list?.length);

      result.item.steps = new Array(6).fill(null).map((nothing, index) => {
        let step = result.item.steps.find(step => step.step === index + 1);
        if (step) {
          step = {
            ...step,
            instructions: step.instructions || '',
            tip: step.tip || '',
            instructions_list: step.instructions_list?.length ? step.instructions_list : [''],
            showTip: !!step.tip,
          };
        }
        else {
          step = {
            instructions: '',
            instructions_list: [''],
            name: '',
            step: index + 1,
            tip: '',
            showTip: false,
          };
        }

        return step;
      });

      result.item.ingredients.map(item => {
        const articleNutrition = item.articles?.find(article => article.portion_size === item.portion_size)?.nutrition_per_net_weight || {};

        return Object.assign(item, {
          name: item.name.trim(),
          nutritions: Object.assign({}, DEFAULT_NUTRITION_VALUE, articleNutrition),
        });
      });

      const mergedIngredients = result.item.ingredients
        .reduce((newObject, current) => {
          const portionArticle = current.articles.find(article => article.portion_size === current.portion_size);

          if (!newObject[`${current.id}-${current.index}`]) {
            const allergens = current.articles.flatMap(article => article?.ingredient?.allergens || []);
            // make allergens unique
            const uniqueAllergens = [...new Map(allergens.map(item => [item.id, item])).values()];

            // sort articles by net weight
            const sortedArticles = current.articles.sort((a, b) => parseInt(a.net_weight) - parseInt(b.net_weight));

            newObject[`${current.id}-${current.index}`] = {
              articles: sortedArticles,
              category: current.category,
              group: current.group,
              id: current.id,
              index: current.index,
              name: current.name,
              allergens: uniqueAllergens,
              portion_size_uom: portionArticle?.portion_size_uom || null,
              tags: current.tags,
            };
          }

          // set people
          Object.assign(newObject[`${current.id}-${current.index}`], {
            people: Object.assign({}, newObject[`${current.id}-${current.index}`]?.people,
              {
                [current.people]: {
                  nutritions: current.nutritions,
                  people: current.people,
                  portion_size: current.portion_size,
                  portion_size_uom: portionArticle?.portion_size_uom || null,
                  price: portionArticle?.price || 0,
                  quantity: current.quantity,
                },
              }),
          });

          return newObject;
        }, {});

      result.item.ingredients = Object.values(mergedIngredients);

      if (result.item.status === 'in-review' || result.item.status === 'peer-test') {
        // get user pending review for the recipe
        recipePendingReview.getByParameters({recipe_id: this.$route.params.id}).then(result => {
          Object.assign(this.currentReviews, result.reviews || {});
          this.setNewIngredientsFromReview();
        });

        // get all completed reviews for the recipe
        if (this.can(Permissions.RECIPES_REVIEWS_VIEW)) {
          recipeReviews.getByParameters({recipe_id: this.$route.params.id}).then(result => {
            Object.assign(this.reviews, result.reviews || {});
            this.$nextTick(() => this.forceDirty = false);
          });
        }
      }

      if (result.item.status === 'ready-to-be-drafted' && result.item.category !== RECIPE_CATEGORY.MARKET) {
        this.showCannotEditDraftRecipeModal = true;
      }
      if (result.item.is_in_drafted_menu || result.item.is_in_scheduled_menu) { this.showPenultimateGateModal = true; }
      if (result.item.is_in_published_menu) { this.showFinalGateModal = true; }

      this.select = {
        carb: result.item.tags.filter(item => item.category.name.toLowerCase().includes('carb')).map(item => item.id),
        dish: result.item.tags.filter(item => item.category.name.toLowerCase().includes('dish')).map(item => item.id),
        geography: result.item.tags.filter(item => item.category.name.toLowerCase().includes('geography')).map(item => item.id),
        protein: result.item.tags.filter(item => item.category.name.toLowerCase().includes('protein')).map(item => item.id),
      };

      this.swaps = structuredClone(result.item.swaps);

      if (result.item.market_item) {
        this.recipeCategory = RECIPE_CATEGORY.MARKET;
      }

      return result;
    },
    checkCharacters(event, step) {
      const acceptableKeys = [
        'Backspace',
        'Delete',
        'ArrowRight',
        'ArrowDown',
        'ArrowLeft',
        'ArrowUp',
      ];

      const limited = this.invalidStep(step);
      if (limited &&
        !acceptableKeys.includes(event.key) &&
        !event.metaKey &&
        !event.ctrlKey
      ) {
        event.preventDefault();
        return false;
      }
    },
    async updateStep(event, step, listIndex) {
      // prevent enter key from creating a new line
      if (event.key === 'Enter') {
        event.preventDefault();
        return false;
      }

      this.checkCharacters(event, step);
      const el = event.target;
      const savedEl = this.saveCursor(el);

      if (typeof listIndex === 'undefined') {
        step.instructions = el.innerText;
      }
      else {
      // update the step instructions, use Vue.set to make sure the change is detected, since we are updating an array item
        Vue.set(step.instructions_list, listIndex, el.innerText);
      }

      // wait for the DOM to update, then restore the cursor position
      await this.$nextTick();
      if (savedEl) {
        this.restoreCursor(el, savedEl);
      }
    },
    disableAddRow(step) {
      return (this.counterCharacters(step).stepRows >= MAX_ROWS_ALLOWED);
    },
    async addStep(step, stepIndex, currentIndex) {
      // prevent enter key from creating a new item, if any of the instructions is empty or the max number of rows is reached
      const noMoreRow = (step.instructions_list.some(instruction => instruction.trim() === '')) || this.disableAddRow(step);
      if (noMoreRow) {
        return;
      }
      // if the step is the last one, add a new one and focus on it
      step.instructions_list.splice(currentIndex + 1, 0, '');
      await this.$nextTick();
      const nextEl = document.getElementById(`instructions_${stepIndex}_${currentIndex + 1}`);
      if (nextEl) {
        nextEl.focus();
      }
    },
    removeStep(step, listIndex) {
      if (!!step.instructions_list[listIndex] && !confirm('Are you sure you want to remove this step?')) {
        return;
      }

      // if only one step left, don't remove it, just clear the content
      if (step.instructions_list.length === 1) {
        step.instructions_list = [''];
        return;
      }

      // if the step is not the last one, remove it and focus on the previous one
      step.instructions_list.splice(listIndex, 1);
    },
    validate() {
      // for classic steps
      if (this.showClassicSteps) {
        return this.item.steps.every(step => {
          const totalCharacters = this.counterCharacters(step).totalCharacters;
          const isValidProportions = this.validateProportions(step.instructions) && this.validateProportions(step.tip);
          return totalCharacters <= MAX_CHARS_FOR_CLASSIC && isValidProportions;
        });
      }

      // for bullet points steps
      return this.item.steps
        .every(
          step => !this.invalidStep(step) &&
          step.instructions_list.every(instruction => this.validatePlaceholders(instruction),
          ));
    },
    usedCount(item) {
      let usedCount = 0;

      if (item) {
        const specialWords = {
          'soy sauce': 'sweet',
        };

        const ignoreItem = specialWords[item.toLowerCase()] || '';

        this.item.steps.forEach(step => {
          let instructions = step.instructions;

          // for bullet points instructions use the instructions_list
          if (!this.showClassicSteps) {
            instructions = step.instructions_list.join(' ');
          }

          const normalized = this.normalize(instructions);
          const words = [...new Set(this.getWordsCustom(item.trim()).flat(2).filter(word => word !== null).sort((a, b) => b.length - a.length))];
          let reg = new RegExp(`\\b(${words.map(word => this.escapeRegExp(word)).join('|')})\\b`, 'gi');

          if (ignoreItem) {
            reg = new RegExp(`(?<!${ignoreItem} )\\b(${words.map(word => this.escapeRegExp(word)).join('|')})\\b`, 'gi');
          }

          while (reg.exec(normalized) !== null) {
            usedCount++;
          }
        });
      }

      return usedCount;
    },
    preventNav(event) {
      if (!this.dirty) return;
      // stop from reloading or redirecting
      event.preventDefault();
      event.returnValue = '';
    },
    selectSwapRecipe(recipe) {
      this.selectedSwapRecipe = recipe;
      this.isAddSwapShow = false;
      this.isSwapIngredientShow = true;
    },
    handleSwapIngredientClose() {
      if (!this.isSwapEdit) {
        this.isAddSwapShow = true;
      }

      this.isSwapIngredientShow = false;
      this.isSwapEdit = false;
      this.selectedSwapRecipe = {};
    },
    async applySwapIngredients(swapFrom, swapTo) {
      this.isAddSwapShow = false;
      this.isSwapIngredientShow = false;

      this.isSwapEdit ? await this.updateSwap(this.selectedSwapRecipe, swapFrom, swapTo) : await this.addSwap(swapFrom, swapTo);
      this.selectedSwapRecipe = {};
      this.isSwapEdit = false;
    },
    handleEditSwapClick(recipe) {
      this.selectedSwapRecipe = recipe;

      this.isSwapEdit = true;
      this.isSwapIngredientShow = true;
    },
    async addSwap(swapFrom, swapTo) {
      try {
        const {item} = await recipeSwaps.post({
          recipe_id: this.item.id,
          swappable_recipe_id: this.selectedSwapRecipe.id,
          swap_from_text: swapFrom,
          swap_to_text: swapTo,
        });

        this.swaps.push(...item);
        this.$toasted.success('New swap added!', {position: 'bottom-center'});
        this.recipeSwapError = '';
      }
      catch (error) {
        this.recipeSwapError = this.generateRecipeSwapError(error);
        this.$toasted.error('Error adding swap!', {position: 'bottom-center'});
      }
    },
    async updateSwap({id, swapFromId, swapToId}, swapFrom, swapTo) {
      try {
        await recipeSwaps.updateById(swapFromId, {swap_text: swapFrom});
        await recipeSwaps.updateById(swapToId, {swap_text: swapTo});

        this.swaps = this.swaps.map(swap => {
          if (swap.swappable_recipe.recipe_id === id) {
            swap.current_recipe.swap_text = swapTo;
            swap.swappable_recipe.swap_text = swapFrom;
          }
          return swap;
        });
        this.$toasted.success('Swap updated!', {position: 'bottom-center'});
        this.recipeSwapError = '';
      }
      catch (error) {
        this.recipeSwapError = this.generateRecipeSwapError(error);
        this.$toasted.error('Error updating swap!', {position: 'bottom-center'});
      }
    },
    async removeSwap(recipeId, swapId) {
      try {
        await recipeSwaps.deleteById(swapId);
        this.swaps = this.swaps.filter(swap => swap.swappable_recipe.recipe_id !== recipeId);
        this.$toasted.success('Swap removed!', {position: 'bottom-center'});
      }
      catch (error) {
        this.recipeSwapError = this.generateRecipeSwapError(error);
        this.$toasted.error('Error removing swap!', {position: 'bottom-center'});
      }
    },
    generateRecipeSwapError(error) {
      try {
        const errorItems = Object.values(error.response.data.errors);
        return errorItems.reduce((acc, item) => {
          acc += item.join(' ');
          return acc + '\n';
        }, '');
      }
      catch (e) {
        console.error(error);
        return 'Something went wrong. Please try again.';
      }
    },
    async handleToggleTip(step, index, event) {
      // focus the tip on toggle
      if (event.target.checked) {
        await this.$nextTick();
        const tipEl = document.getElementById('tips_' + (index));
        if (tipEl) {
          tipEl.focus();
        }
      }
      else if (step.tip) {
        if (!confirm('Are you sure you want to remove the tip? The tip will be cleared on toggle.')) {
          event.preventDefault();
          return;
        }
        step.tip = '';
      }
    },
    validatePlaceholders(instructions) {
      // FUTURE TODO: add validation for all placeholders ([b], [i], (Spicy!))
      return this.validateProportions(instructions);
    },
    validateProportions(input) {
      const stack = [];
      let validProportions = true;

      for (const char of input) {
        if (char === '{') {
          validProportions = false;
          stack.push('{');
        }
        else if (char === '}') {
          if (stack.length === 0) {
            // There is no matching opening brace
            return false;
          }
          stack.pop();
        }
      }

      // check if there 2 '/' (20/30/40) between the proportions and all the values are numbers
      const regex = /\{([^}]+)\}/;
      const match = input.match(regex);
      if (match) {
        const word = match[1];
        // we will replace all '/' and '.' to check if the given values are numbers
        const allNumbers = Number(word.replaceAll('/', '').replaceAll('.', ''));
        validProportions = !isNaN(allNumbers) && word.slice(-1) !== '/' && word.split('').reduce((a, c) => c === '/' ? ++a : a, 0) === NO_OF_PEOPLE_SPLIT;
      }

      // If the stack is empty, all opening braces have a matching closing brace
      return validProportions && stack.length === 0;
    },
    convertToBulletPoints() {
      this.item.steps.forEach(i => {
        i.instructions_list = i.instructions.split(/(?<!\d)\.(?=\s|$)/).filter(it => !!it).map(it => it.trim() + '.');
      });
      this.showClassicSteps = false;
    },
  },
};

</script>

<style>
  .char-limit {
    color: #ED8670;
    animation: blink 400ms linear 3;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
  }

  .char-limit small {
    font-weight: bold;
  }

  span.highlighted,
  .highlighted > input[type="text"]::first-line,
  .highlighted .selectize-control .selectize-input .item,
  .highlighted .selectize-control .selectize-input.disabled .item,
  .highlighted .selectize-control.multi .selectize-input .item,
  .highlighted .selectize-control.multi .selectize-input.disabled .item,
  .highlighted .card .card-header .card-title
  {
    background-color: #E0E325;
  }

  .instruction {
    z-index: 2;
    min-height: 40px;
  }
  .instruction.highlighted {
    background-color: #E0E325;
  }

  .modal-backdrop {
      opacity: 0.8;
  }

  #modal-subrecipe .modal-dialog {

      max-width: 65%;
  }

  #modal-ingredients .modal-body {

      padding: 0;
  }

  .font-bold {

      font-weight: bold;
  }

  #modal-gate1 .modal-header,
  #modal-gate2 .modal-header  {

      border-bottom: 0;
      padding: 5px 5px 0 5px;
  }

  #modal-gate1 .modal-header button,
  #modal-gate2 .modal-header button {

      outline: none;
  }

  .invalid {
    border-color: var(--red) !important;
  }
  .invalid.text-right {
    color: var(--red);
  }

  .disabled-step {
    min-height: 40px;
    padding: 0.375rem 0.75rem;
    border: 1px dashed rgba(0, 40, 100, 0.12) !important;
  }

  @keyframes blink {
    50% {
      color: #495057;
    }
  }

  @media print {
    .print-hidden {
      display: none;
    }
  }

  .ghost {
    background-color: #f5f7fb;
  }
</style>
